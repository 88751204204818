var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expansion-panels', _vm._l(_vm.skillScores, function (item, i) {
    var _item$getSkillPreview;

    return _c('v-expansion-panel', {
      key: i,
      staticClass: "my-2"
    }, [_c('v-expansion-panel-header', {
      staticClass: "\n        font-weight-medium\n        text-body-1\n        d-flex\n        flex-row\n        justify-space-between\n      "
    }, [_c('div', [_vm._v(_vm._s((_item$getSkillPreview = item.getSkillPreview()) === null || _item$getSkillPreview === void 0 ? void 0 : _item$getSkillPreview.getTitle()))]), _c('div', {
      staticClass: "d-flex flex-row justify-end"
    }, [_c('div', {
      staticClass: "timer"
    }, [_c('v-chip', {
      staticClass: "ma-2",
      attrs: {
        "label": "",
        "text-color": "black"
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "black",
        "left": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.mdiTimer) + " ")]), _vm._v(" " + _vm._s(_vm.skillDuration(item)) + " ")], 1)], 1), _c('div', {
      staticClass: "score"
    }, [_c('v-chip', {
      staticClass: "ma-2",
      attrs: {
        "label": "",
        "text-color": "black"
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "black",
        "left": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.mdiCheckboxMultipleMarked) + " ")]), _vm._v(" " + _vm._s((item.getScore() * 100).toFixed(2)) + "% ")], 1)], 1)])]), _c('v-expansion-panel-content', {
      staticClass: "text-subtitle-1"
    }, _vm._l(item.getQuestionAnswerList(), function (fullQ, i) {
      return _c('div', {
        key: i,
        staticClass: "question"
      }, [_c('report-skill-question', {
        attrs: {
          "question": fullQ === null || fullQ === void 0 ? void 0 : fullQ.getQuestionView(),
          "answer": fullQ === null || fullQ === void 0 ? void 0 : fullQ.getCompleteAnswerData()
        }
      })], 1);
    }), 0)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }