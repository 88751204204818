var render = function () {
  var _vm$bestSubmission, _vm$bestSubmission$su;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "mb-5",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.question.getTitle()) + " ")]), _vm.questionType === 'MCQ' ? _c('v-card-text', [_c('div', {
    staticClass: "qs",
    domProps: {
      "innerHTML": _vm._s(_vm.currentQuestionHtml)
    }
  }), _c('div', {
    staticClass: "pt-5 pl-1 mb-n5"
  }, [_vm._v("Correct Options")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, _vm._l(_vm.mcqOptions, function (opt, idx) {
    return _c('v-col', {
      key: opt.getStatementHtml(),
      attrs: {
        "cols": "3"
      }
    }, [_c('v-checkbox', {
      staticClass: "radio__option",
      attrs: {
        "multiple": "",
        "value": opt.getId(),
        "color": "primary",
        "readonly": "",
        "hide-details": ""
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function fn() {
          return [_c('div', {
            staticClass: "radio__parent mt-3",
            domProps: {
              "innerHTML": _vm._s(opt.getStatementHtml())
            }
          })];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.correctMcqAnswer,
        callback: function callback($$v) {
          _vm.correctMcqAnswer = $$v;
        },
        expression: "correctMcqAnswer"
      }
    })], 1);
  }), 1), _c('div', {
    staticClass: "pt-5 pl-1 mb-n5"
  }, [_vm._v("Candidate's Selections")]), _vm.questionType === 'MCQ' ? _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, _vm._l(_vm.mcqOptions, function (opt, idx) {
    return _c('v-col', {
      key: opt.getStatementHtml(),
      attrs: {
        "cols": "3"
      }
    }, [_c('v-checkbox', {
      staticClass: "radio__option candidate",
      attrs: {
        "multiple": "",
        "value": opt.getId(),
        "color": _vm.mcqAllCorrect ? 'success' : 'red',
        "readonly": "",
        "hide-details": ""
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function fn() {
          return [_c('div', {
            staticClass: "radio__parent mt-3",
            domProps: {
              "innerHTML": _vm._s(opt.getStatementHtml())
            }
          })];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.candidateMcqAnswer,
        callback: function callback($$v) {
          _vm.candidateMcqAnswer = $$v;
        },
        expression: "candidateMcqAnswer"
      }
    })], 1);
  }), 1) : _vm._e()], 1) : _vm.questionType === 'PROBLEM' ? _c('v-card-text', [_c('div', {
    staticClass: "my-5",
    domProps: {
      "innerHTML": _vm._s(_vm.currentQuestionHtml)
    }
  }), !_vm.noSubmission && _vm.bestSubmission ? _c('v-row', {
    staticClass: "flex-column"
  }, [_c('v-card', {
    attrs: {
      "elevation": "0",
      "outlined": "",
      "rounded": "lg"
    }
  }, [_c('v-card-title', {
    staticClass: "text-body-1"
  }, [_vm._v(" Best Submission ")]), _c('v-card-text', [_c('pre', [_vm._v(_vm._s((_vm$bestSubmission = _vm.bestSubmission) === null || _vm$bestSubmission === void 0 ? void 0 : (_vm$bestSubmission$su = _vm$bestSubmission.submission) === null || _vm$bestSubmission$su === void 0 ? void 0 : _vm$bestSubmission$su.code) + "\n            ")])])], 1)], 1) : _c('v-row', {
    staticClass: "pa-5"
  }, [_vm.noSubmission ? _c('div', [_vm._v("No submission considered")]) : _c('div', [_vm._v("Loading ...")])])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }