<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="(item, i) in skillScores" :key="i" class="my-2">
      <v-expansion-panel-header
        class="
          font-weight-medium
          text-body-1
          d-flex
          flex-row
          justify-space-between
        "
      >
        <div>{{ item.getSkillPreview()?.getTitle() }}</div>
        <div class="d-flex flex-row justify-end">
          <div class="timer">
            <v-chip class="ma-2" label text-color="black">
              <v-icon color="black" left> {{ mdiTimer }} </v-icon>
              {{ skillDuration(item) }}
            </v-chip>
          </div>
          <div class="score">
            <v-chip class="ma-2" label text-color="black">
              <v-icon color="black" left>
                {{ mdiCheckboxMultipleMarked }}
              </v-icon>
              {{ (item.getScore() * 100).toFixed(2) }}%
            </v-chip>
          </div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="text-subtitle-1">
        <div
          class="question"
          v-for="(fullQ, i) in item.getQuestionAnswerList()"
          :key="i"
        >
          <report-skill-question
            :question="fullQ?.getQuestionView()"
            :answer="fullQ?.getCompleteAnswerData()"
          />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapState } from "vuex";
import ReportSkillQuestion from "./ReportSkillQuestion.vue";
import { mdiTimer, mdiCheckboxMultipleMarked } from "@mdi/js";
import formattedTimeMixin from "@/mixins/formattedTimeMixin";
export default {
  components: { ReportSkillQuestion },
  data() {
    return {
      mdiTimer,
      mdiCheckboxMultipleMarked,
    };
  },
  mixins: [formattedTimeMixin],
  computed: {
    ...mapState("recruiter", ["candidateReport"]),
    skillScores() {
      return this.candidateReport?.getSkillScoresList();
    },
  },
  methods: {
    skillDuration(item) {
      return this.displayTime(
        item?.getSkillPreview()?.getMetaData()?.getTimeLimit()
      );
    },
  },
  created() {},
};
</script>