<template>
  <v-card outlined rounded="xl">
    <v-card-title>
      <v-row
        class="text-h5 font-weight-bold d-flex pa-0"
        align="center"
        justify="center"
      >
        <v-col cols="1">
          <v-btn class="text-none" color="#122333" @click="$router.go(-1)">
            <v-icon color="white">
              {{ mdiArrowLeft }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col class="ml-5">
          {{ candidateReport?.getName() }}
          <div class="text-subtitle-2 gray--text font-weight-regular">
            {{ candidateReport?.getEmail() }}
          </div>
          <div>
            <v-rating
              color="primary"
              hover
              length="5"
              size="48"
              v-model="candidateRating"
              @input="candidateRatingUpdate"
            ></v-rating>
          </div>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="text-none px-8">
      <v-row class="d-flex flex-row-reverse">
        <v-dialog v-model="emailDialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              text
              class="text-none text-body-1"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left color="primary" class="">
                {{ mdiEmail }}
              </v-icon>
              Send Mail
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 primary--text font-weight-bold">
              Mail Candidate
            </v-card-title>

            <v-card-text>
              <v-form ref="emailForm">
                <v-row justify="center">
                  <v-col cols="6">
                    <v-radio-group v-model="mailType" mandatory>
                      <v-radio label="Cleared" :value="1"></v-radio>
                      <v-radio label="Failed" :value="2"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="6" class="align-self-end">
                    <v-btn
                      class="py-3 px-5 text-none text-body-1"
                      color="primary"
                      @click="sendMail"
                      :loading="sendingMail"
                    >
                      Send Email
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>
        <v-col cols="12" md="5" class="right__border">
          <!-- test details -->
          <v-row>
            <v-col cols="4" class="text-body-1 font-weight-medium black--text">
              Test Name
            </v-col>
            <v-col cols="7" class="text-body-1">
              {{ assessment?.getTitle() }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-body-1 font-weight-medium black--text">
              Invited
            </v-col>
            <v-col cols="7" class="text-body-1">
              {{ inviteDate }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-body-1 font-weight-medium black--text">
              Started
            </v-col>
            <v-col cols="7" class="text-body-1">
              <span v-if="hasStarted">
                {{ startedDate }}
              </span>
              <span v-else> - </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-body-1 font-weight-medium black--text">
              Completed
            </v-col>
            <v-col cols="7" class="text-body-1">
              <span v-if="hasCompleted">
                {{ completedDate }}
              </span>
              <span v-else> - </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-body-1 font-weight-medium black--text">
              Test Duration
            </v-col>
            <v-col cols="7" class="text-body-1">
              {{ totalTestDuration }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-body-1 font-weight-medium black--text">
              Status
            </v-col>
            <v-col cols="7" class="text-body-1">
              <v-chip :color="candidateStatusColor">
                {{ candidateStatus }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-body-1 font-weight-medium black--text">
              Stage
            </v-col>
            <v-col cols="7" class="text-body-1 mt-n1">
              <v-select
                dense
                outlined
                :items="testStagesSelector"
                item-text="text"
                item-value="stage"
                v-model="currentStage"
                @change="candidateStageUpdate"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4" class="black--text right__border">
          <v-card class="pa-0 ma-0 mx-2" rounded="0" elevation="0">
            <v-card-text class="pa-0 ma-0 black--text">
              <v-row class="text-body-1 font-weight-medium">
                <v-col cols="6">Skills</v-col>
                <v-col>Score</v-col>
              </v-row>
              <v-row
                class="text-body-1 px-0 pb-3"
                v-for="sk in testSkills"
                :key="sk.title"
              >
                <v-col>{{ sk.title }}</v-col>
                <v-col> {{ getScoreById(sk.id) }} </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="px-0 ma-0 mt-8">
              <v-row class="text-body-1 font-weight-medium score__border">
                <v-col cols="6">Overall Score:</v-col>
                <v-col>{{ overallScore }}</v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" class="black--text">
          <v-card class="pa-0 ma-0" elevation="0">
            <v-card-text class="pa-0 ma-0 black--text">
              <v-row class="text-body-1 font-weight-medium">
                <v-col> Anti Cheating Report </v-col>
              </v-row>
              <v-row v-for="ac in antiCheats" :key="ac.title">
                <v-col class="text-body-1">
                  {{ ac.title }}
                </v-col>
                <v-col>
                  {{ ac.value }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="candidateReport?.getSkillScoresList()">
        <v-col cols="12">
          <v-card-title class="primary--text"> Answer Details </v-card-title>
          <v-card class="ma-0 pa-5" elevation="0" outlined>
            <v-card-text>
              <skill-report />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="black--text">
        <v-col cols="5">
          <v-row>
            <v-col cols="4">
              <div class="text-body-1 font-weight-medium">Notes</div>
            </v-col>
            <v-col cols="8">
              <v-textarea
                id="notes"
                v-model="candidateNotes"
                rows="5"
                outlined
                :disabled="!editing"
                placeholder="Click 'edit' to update"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="d-flex flex-column-reverse">
          <div class="pb-8 d-flex justify-space-between">
            <v-btn
              class="text-none text-body-1"
              text
              @click="editing = true"
              v-if="!editing"
              outlined
            >
              Edit Notes
            </v-btn>
            <v-btn
              class="text-body-1 text-none"
              color="primary"
              :disabled="!editing"
              @click="saveCandidateInfo"
              v-if="editing"
            >
              Save Notes
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiArrowLeft, mdiEmail } from "@mdi/js";
import moment from "moment";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import formattedTimeMixin from "@/mixins/formattedTimeMixin";
import SkillReport from "./CandidateSkillReport.vue";
export default {
  components: { SkillReport },
  data() {
    return {
      mdiEmail,
      mdiArrowLeft,
      emailDialog: false,
      mailType: "",
      editing: false,
      candidateRating: 0,
      candidateNotes: "",
      updatedStage: null,
      sendingMail: false,
    };
  },
  mixins: [formattedTimeMixin],
  computed: {
    ...mapState("recruiter", [
      "candidate",
      "assessment",
      "assessmentView",
      "candidateReport",
      "fetchAssessmentStatus",
      "fetchCandidateReportStatus",
    ]),
    ...mapGetters("recruiter", [
      "testStatusPrettyMessage",
      "cheatingPrettyMessage",
      "testStagesSelector",
      "testStatusColor",
      "testStages",
      "emailTypes",
    ]),
    currentStage: {
      get: function () {
        let stage = this.candidateReport?.getStage();
        if (stage === 0) stage = 1;
        return this.testStages[stage];
      },
      set: function (val) {
        this.updatedStage = val;
      },
    },
    overallScore() {
      if (this.candidateReport?.getSkillScoresList()?.length > 0) {
        if (Number.isNaN(this.candidateReport.getOverallScore())) {
          return `0%`;
        } else {
          return `${(this.candidateReport.getOverallScore() * 100).toFixed(
            2
          )}%`;
        }
      } else {
        return "-";
      }
    },
    testSkills() {
      if (this.fetchAssessmentStatus) {
        const skillIds = this.assessment
          ?.getDataView()
          ?.getTestView()
          ?.getSkillsList();
        const childPreviews = this.assessmentView?.getChildPreviewsList();
        const skillsText = [];
        childPreviews.forEach((p) => {
          if (
            skillIds.includes(p?.getContentPreview()?.getId()) &&
            p?.getContentPreview()?.getContentType() === 7
          ) {
            skillsText.push({
              title: p?.getContentPreview()?.getTitle(),
              id: p?.getContentPreview()?.getId(),
            });
          }
        });
        return skillsText;
      }
    },
    antiCheats() {
      console.log(this.assessment?.toObject());
      if (this.fetchCandidateReportStatus) {
        const antiCheatingReport =
          this.candidateReport?.getAntiCheatingReport();
        const msgs = [];
        msgs.push({
          title: "Disconnected",
          value: antiCheatingReport?.getDisconnectedCount() ?? 0,
        });
        msgs.push({
          title: "Exited full screen",
          value: antiCheatingReport?.getFullScreenExitCount() ?? 0,
        });
        msgs.push({
          title: "Switched tab",
          value:
            (antiCheatingReport?.getTabSwitchCount() ?? 0) +
            (antiCheatingReport?.getWindowSwitchCount() ?? 0),
        });

        return msgs;
      }
    },
    candidateStatus() {
      return this.testStatusPrettyMessage[this.candidateReport?.getTestStatus()];
    },
    candidateStatusColor() {
      return this.testStatusColor[this.candidateReport?.getTestStatus()];
    },
    inviteDate() {
      if (this.fetchCandidateReportStatus) {
        return moment
          .unix(this.candidateReport?.getInviteDate() / 1000)
          .format("hh:mm A, DD/MM/YYYY");
      }
    },
    hasStarted() {
      return this.candidateReport?.getTestTimeInfo()?.getStartedAt() > 0;
    },
    hasCompleted() {
      return this.candidateReport?.getTestTimeInfo()?.getEndedAt() > 0;
    },
    startedDate() {
      return moment
        .unix(this.candidateReport?.getTestTimeInfo()?.getStartedAt() / 1000)
        .format("hh:mm A, DD/MM/YYYY");
    },
    completedDate() {
      return moment
        .unix(this.candidateReport?.getTestTimeInfo()?.getEndedAt() / 1000)
        .format("hh:mm A, DD/MM/YYYY");
    },
    totalTestDuration() {
      return this.displayTime(
        this.candidateReport?.getTestTimeInfo()?.getDuration()
      );
    },
  },
  methods: {
    ...mapActions("recruiter", [
      "emailCandidate",
      "fetchAssessment",
      "updateCandidateStage",
      "updateCandidateNotes",
      "fetchCandidateReport",
      "updateCandidateRating",
    ]),
    getScoreById(id) {
      const skillScores = this.candidateReport?.getSkillScoresList() ?? [];
      if (skillScores.length > 0) {
        for (const skill of skillScores) {
          if (skill.getSkillPreview()?.getId() === id) {
            if (!Number.isNaN(skill?.getScore())) {
              return `${(skill.getScore() * 100).toFixed(2)}%`;
            } else {
              return "-";
            }
          }
        }
      } else return "-";
    },
    candidateRatingUpdate() {
      this.updateCandidateRating({
        testId: this.assessment?.getId(),
        cid: this.candidateReport?.getCandidateId(),
        rating: this.candidateRating,
      })
        .then((r) => {
          this.$store.dispatch("notifs/addNotif", {
            text: `Updated Successfully!`,
            type: "success",
            duration: 1000,
          });
        })
        .catch((err) => {
          this.$store.dispatch("notifs/addNotif", {
            text: `Error! Please refresh and try again!`,
            type: "error",
          });
        });
    },
    candidateStageUpdate() {
      this.updateCandidateStage({
        testId: this.assessment?.getId(),
        cid: this.candidateReport?.getCandidateId(),
        stage: this.updatedStage,
      })
        .then((r) => {
          this.$store.dispatch("notifs/addNotif", {
            text: `Updated Successfully!`,
            type: "success",
            duration: 1000,
          });
        })
        .catch((err) => {
          this.$store.dispatch("notifs/addNotif", {
            text: `Error! Please refresh and try again!`,
            type: "error",
          });
        });
    },
    saveCandidateInfo() {
      this.updateCandidateNotes({
        testId: this.assessment?.getId(),
        cid: this.candidateReport?.getCandidateId(),
        note: this.candidateNotes,
      })
        .then((r) => {
          this.$store.dispatch("notifs/addNotif", {
            text: `Updated Successfully!`,
            type: "success",
          });
          this.editing = false;
        })
        .catch((err) => {
          this.$store.dispatch("notifs/addNotif", {
            text: `Error! Please refresh and try again!`,
            type: "error",
          });
        });
    },
    sendMail() {
      this.sendingMail = true;
      this.emailCandidate({
        testId: this.assessment?.getId(),
        cid: this.candidate?.getId(),
        etype: this.emailTypes[this.mailType],
      })
        .then((r) => {
          this.sendingMail = false;
          this.emailDialog = false;
          this.$store.dispatch("notifs/addNotif", {
            text: "Emailed Candidate Successfully!",
            type: "success",
          });
          this.sendingMail = false;
          this.emailDialog = false;
        })
        .catch((err) => {
          this.$store.dispatch("notifs/addNotif", {
            text: err.message ?? "Error occured",
            type: "error",
          });
          this.sendingMail = false;
          console.log(err);
        });
    },
    fetchReport() {
      this.fetchCandidateReport({
        cid: this.$route.params.userId,
        testId: this.assessment?.getId(),
      }).then(() => {
        this.candidateRating = this.candidateReport?.getRating();
        this.candidateNotes = this.candidateReport?.getNotes();
        this.updatedStage = this.testStages[this.candidateReport?.getStage()];
      });
    },
  },
  created() {
    if (!this.assessment) {
      this.fetchAssessment({ url: this.$route.params.url }).then((r) => {
        console.log("assessment fetch");
        if (!this.candidate) {
          this.fetchReport();
        }
      });
    } else {
      this.fetchReport();
    }
  },
};
</script>
<style scoped>
.right__border {
  border-right: 1px solid #c4c4c4;
}
.score__border {
  border-top: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
}
</style>
