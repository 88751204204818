var render = function () {
  var _vm$candidateReport, _vm$candidateReport2, _vm$assessment, _vm$candidateReport3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": "",
      "rounded": "xl"
    }
  }, [_c('v-card-title', [_c('v-row', {
    staticClass: "text-h5 font-weight-bold d-flex pa-0",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "#122333"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiArrowLeft) + " ")])], 1)], 1), _c('v-col', {
    staticClass: "ml-5"
  }, [_vm._v(" " + _vm._s((_vm$candidateReport = _vm.candidateReport) === null || _vm$candidateReport === void 0 ? void 0 : _vm$candidateReport.getName()) + " "), _c('div', {
    staticClass: "text-subtitle-2 gray--text font-weight-regular"
  }, [_vm._v(" " + _vm._s((_vm$candidateReport2 = _vm.candidateReport) === null || _vm$candidateReport2 === void 0 ? void 0 : _vm$candidateReport2.getEmail()) + " ")]), _c('div', [_c('v-rating', {
    attrs: {
      "color": "primary",
      "hover": "",
      "length": "5",
      "size": "48"
    },
    on: {
      "input": _vm.candidateRatingUpdate
    },
    model: {
      value: _vm.candidateRating,
      callback: function callback($$v) {
        _vm.candidateRating = $$v;
      },
      expression: "candidateRating"
    }
  })], 1)])], 1)], 1), _c('v-card-text', {
    staticClass: "text-none px-8"
  }, [_c('v-row', {
    staticClass: "d-flex flex-row-reverse"
  }, [_c('v-dialog', {
    attrs: {
      "width": "500"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "text-none text-body-1",
          attrs: {
            "color": "primary",
            "text": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "left": "",
            "color": "primary"
          }
        }, [_vm._v(" " + _vm._s(_vm.mdiEmail) + " ")]), _vm._v(" Send Mail ")], 1)];
      }
    }]),
    model: {
      value: _vm.emailDialog,
      callback: function callback($$v) {
        _vm.emailDialog = $$v;
      },
      expression: "emailDialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5 primary--text font-weight-bold"
  }, [_vm._v(" Mail Candidate ")]), _c('v-card-text', [_c('v-form', {
    ref: "emailForm"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "mandatory": ""
    },
    model: {
      value: _vm.mailType,
      callback: function callback($$v) {
        _vm.mailType = $$v;
      },
      expression: "mailType"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Cleared",
      "value": 1
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Failed",
      "value": 2
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "align-self-end",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "py-3 px-5 text-none text-body-1",
    attrs: {
      "color": "primary",
      "loading": _vm.sendingMail
    },
    on: {
      "click": _vm.sendMail
    }
  }, [_vm._v(" Send Email ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "right__border",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-body-1 font-weight-medium black--text",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Test Name ")]), _c('v-col', {
    staticClass: "text-body-1",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s((_vm$assessment = _vm.assessment) === null || _vm$assessment === void 0 ? void 0 : _vm$assessment.getTitle()) + " ")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-body-1 font-weight-medium black--text",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Invited ")]), _c('v-col', {
    staticClass: "text-body-1",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s(_vm.inviteDate) + " ")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-body-1 font-weight-medium black--text",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Started ")]), _c('v-col', {
    staticClass: "text-body-1",
    attrs: {
      "cols": "7"
    }
  }, [_vm.hasStarted ? _c('span', [_vm._v(" " + _vm._s(_vm.startedDate) + " ")]) : _c('span', [_vm._v(" - ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-body-1 font-weight-medium black--text",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Completed ")]), _c('v-col', {
    staticClass: "text-body-1",
    attrs: {
      "cols": "7"
    }
  }, [_vm.hasCompleted ? _c('span', [_vm._v(" " + _vm._s(_vm.completedDate) + " ")]) : _c('span', [_vm._v(" - ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-body-1 font-weight-medium black--text",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Test Duration ")]), _c('v-col', {
    staticClass: "text-body-1",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s(_vm.totalTestDuration) + " ")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-body-1 font-weight-medium black--text",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Status ")]), _c('v-col', {
    staticClass: "text-body-1",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-chip', {
    attrs: {
      "color": _vm.candidateStatusColor
    }
  }, [_vm._v(" " + _vm._s(_vm.candidateStatus) + " ")])], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-body-1 font-weight-medium black--text",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Stage ")]), _c('v-col', {
    staticClass: "text-body-1 mt-n1",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-select', {
    attrs: {
      "dense": "",
      "outlined": "",
      "items": _vm.testStagesSelector,
      "item-text": "text",
      "item-value": "stage"
    },
    on: {
      "change": _vm.candidateStageUpdate
    },
    model: {
      value: _vm.currentStage,
      callback: function callback($$v) {
        _vm.currentStage = $$v;
      },
      expression: "currentStage"
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "black--text right__border",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "pa-0 ma-0 mx-2",
    attrs: {
      "rounded": "0",
      "elevation": "0"
    }
  }, [_c('v-card-text', {
    staticClass: "pa-0 ma-0 black--text"
  }, [_c('v-row', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v("Skills")]), _c('v-col', [_vm._v("Score")])], 1), _vm._l(_vm.testSkills, function (sk) {
    return _c('v-row', {
      key: sk.title,
      staticClass: "text-body-1 px-0 pb-3"
    }, [_c('v-col', [_vm._v(_vm._s(sk.title))]), _c('v-col', [_vm._v(" " + _vm._s(_vm.getScoreById(sk.id)) + " ")])], 1);
  })], 2), _c('v-card-actions', {
    staticClass: "px-0 ma-0 mt-8"
  }, [_c('v-row', {
    staticClass: "text-body-1 font-weight-medium score__border"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v("Overall Score:")]), _c('v-col', [_vm._v(_vm._s(_vm.overallScore))])], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "black--text",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-text', {
    staticClass: "pa-0 ma-0 black--text"
  }, [_c('v-row', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_c('v-col', [_vm._v(" Anti Cheating Report ")])], 1), _vm._l(_vm.antiCheats, function (ac) {
    return _c('v-row', {
      key: ac.title
    }, [_c('v-col', {
      staticClass: "text-body-1"
    }, [_vm._v(" " + _vm._s(ac.title) + " ")]), _c('v-col', [_vm._v(" " + _vm._s(ac.value) + " ")])], 1);
  })], 2)], 1)], 1)], 1), (_vm$candidateReport3 = _vm.candidateReport) !== null && _vm$candidateReport3 !== void 0 && _vm$candidateReport3.getSkillScoresList() ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-title', {
    staticClass: "primary--text"
  }, [_vm._v(" Answer Details ")]), _c('v-card', {
    staticClass: "ma-0 pa-5",
    attrs: {
      "elevation": "0",
      "outlined": ""
    }
  }, [_c('v-card-text', [_c('skill-report')], 1)], 1)], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "black--text"
  }, [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_vm._v("Notes")])]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-textarea', {
    attrs: {
      "id": "notes",
      "rows": "5",
      "outlined": "",
      "disabled": !_vm.editing,
      "placeholder": "Click 'edit' to update"
    },
    model: {
      value: _vm.candidateNotes,
      callback: function callback($$v) {
        _vm.candidateNotes = $$v;
      },
      expression: "candidateNotes"
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "d-flex flex-column-reverse"
  }, [_c('div', {
    staticClass: "pb-8 d-flex justify-space-between"
  }, [!_vm.editing ? _c('v-btn', {
    staticClass: "text-none text-body-1",
    attrs: {
      "text": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.editing = true;
      }
    }
  }, [_vm._v(" Edit Notes ")]) : _vm._e(), _vm.editing ? _c('v-btn', {
    staticClass: "text-body-1 text-none",
    attrs: {
      "color": "primary",
      "disabled": !_vm.editing
    },
    on: {
      "click": _vm.saveCandidateInfo
    }
  }, [_vm._v(" Save Notes ")]) : _vm._e()], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }