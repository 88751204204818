<template>
  <div>
    <v-card elevation="0" class="mb-5">
      <v-card-title>
        {{ question.getTitle() }}
      </v-card-title>
      <v-card-text v-if="questionType === 'MCQ'">
        <div class="qs" v-html="currentQuestionHtml"></div>
        <div class="pt-5 pl-1 mb-n5">Correct Options</div>
        <v-row align="center">
          <v-col
            cols="3"
            v-for="(opt, idx) in mcqOptions"
            :key="opt.getStatementHtml()"
          >
            <v-checkbox
              multiple
              v-model="correctMcqAnswer"
              :value="opt.getId()"
              class="radio__option"
              color="primary"
              readonly
              hide-details
            >
              <template v-slot:label>
                <div
                  class="radio__parent mt-3"
                  v-html="opt.getStatementHtml()"
                ></div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <div class="pt-5 pl-1 mb-n5">Candidate's Selections</div>
        <v-row v-if="questionType === 'MCQ'" align="center">
          <v-col
            cols="3"
            v-for="(opt, idx) in mcqOptions"
            :key="opt.getStatementHtml()"
          >
            <v-checkbox
              multiple
              v-model="candidateMcqAnswer"
              :value="opt.getId()"
              class="radio__option candidate"
              :color="mcqAllCorrect ? 'success' : 'red'"
              readonly
              hide-details
            >
              <template v-slot:label>
                <div
                  class="radio__parent mt-3"
                  v-html="opt.getStatementHtml()"
                ></div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else-if="questionType === 'PROBLEM'">
        <div class="my-5" v-html="currentQuestionHtml"></div>
        <v-row v-if="!noSubmission && bestSubmission" class="flex-column">
          <v-card elevation="0" outlined rounded="lg">
            <v-card-title class="text-body-1"> Best Submission </v-card-title>
            <v-card-text>
              <!-- make sure opening pre and template is on same line -->
              <!-- otherwise indentation weird on display -->
              <pre
                >{{ bestSubmission?.submission?.code }}
              </pre>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row class="pa-5" v-else>
          <div v-if="noSubmission">No submission considered</div>
          <div v-else>Loading ...</div>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      bestSubmission: null,
      noSubmission: false,
    };
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    answer: {
      required: true,
    },
  },
  computed: {
    ...mapGetters("candidate", ["getContentTypes"]),
    ...mapState("judge", ["fetchSubmissionDetailStatus"]),
    questionType() {
      return this.getContentTypes[this.question?.getType()];
    },
    currentQuestionHtml() {
      if (this.questionType === "MCQ") {
        return this.question?.getDataView()?.getMcqView()?.getStatementHtml();
      } else if (this.questionType === "SUBJECTIVE") {
        return this.question
          ?.getDataView()
          ?.getSubjectiveView()
          ?.getDescriptionHtml();
      } else if (this.questionType === "PROBLEM") {
        return this.question
          ?.getDataView()
          ?.getProblemView()
          ?.getStatementView();
      } else {
        return `<div>Error loading question!</div>`;
      }
    },
    mcqOptions() {
      if (this.questionType === "MCQ") {
        return this.question?.getDataView()?.getMcqView()?.getOptionList();
      }
      return null;
    },
    currentAnswerData() {
      if (this.questionType === "MCQ") {
        return this.answer?.getMcqAnswerData();
      } else if (this.questionType === "PROBLEM") {
        return this.answer?.getProblemAnswerData();
      } else if (this.questionType === "SUBJECTIVE") {
        return this.answer?.getSubjectiveAnswerData();
      }
    },
    correctMcqAnswer() {
      if (this.questionType === "MCQ" && !this.noSubmission)
        return this.currentAnswerData?.getCorrectOptionIdList();
    },
    candidateMcqAnswer() {
      if (this.questionType === "MCQ" && !this.noSubmission)
        return this.currentAnswerData?.getOptionIdList();
    },
    mcqAllCorrect() {
      if (this.correctMcqAnswer.length !== this.candidateMcqAnswer.length) {
        return false;
      }
      const union = Array.from(
        new Set([...this.correctMcqAnswer, ...this.candidateMcqAnswer])
      );
      if (union.length !== this.correctMcqAnswer.length) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions("judge", ["fetchSubmissionDetail"]),
    fetchBestSubmission() {
      if (this.questionType === "PROBLEM" && !this.noSubmission) {
        this.fetchSubmissionDetail({
          submissionId: this.currentAnswerData
            .getBestSubmissionPreview()
            ?.getId(),
        })
          .then((r) => {
            this.bestSubmission = r.toObject();
          })
          .catch((r) => {
            this.noSubmission = true;
          });
      }
    },
  },
  created() {
    if (!this.answer) {
      // if completeAnswerData in proto field
      // is undefined
      this.noSubmission = true;
    }
    this.fetchBestSubmission();
  },
};
</script>
<style scoped>
.radio__option {
  padding: 0.5rem;
  border-radius: 1rem;
  border: 2px solid #c4c4c4;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>